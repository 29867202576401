import React from 'react'

import getClassName from '../utils/getClassName'

const DEFAULT_SIZE = "btn-nl"

const mapBaseSize = {
  small: "btn-sm",
  normal: DEFAULT_SIZE,
  medium: "btn-md",
  large: "btn-lg",
  spl: "btn-spl"
}

const DEFAULT_COLOR_SCHEMA = "btn-prim"

const mapBaseColorSchema = {
  prim: DEFAULT_COLOR_SCHEMA,
  sec: "btn-sec",
  primOutline: "btn-prim-outline",
  secOutline: "btn-sec-outline"
}

const getButtonSize = getClassName(mapBaseSize, DEFAULT_SIZE)
const getButtonColorSchema = getClassName(mapBaseColorSchema, DEFAULT_COLOR_SCHEMA)

export default function Button(props) {
  const { size = "normal", colorSchema = "prim", className, ...restProps } = props
  return (
    <button
      {...restProps}
      className={`${getButtonSize(size)} ${getButtonColorSchema(colorSchema)} ${className ? className: ""}`.trim()}
    ></button>
  )
}